// table-expand.vue
<style scoped>
    .expand-row{
        margin-bottom: 16px;
    }
</style>
<template>
  <div>
    <Row class="expand-row">
      <Col span="8">
        <span class="expand-key">Activation status : </span>
        <span v-if="row.isEnabled"
              class="expand-value chips chips--success"
        ><strong>Enabled</strong></span>
        <span v-else
              class="expand-value chips chips--red"
        ><strong>Disabled</strong></span>
      </Col>
      <Col span="8">
        <span class="expand-key">Verification status : </span>
        <span v-if="row.validation_status === 'WAITING_FOR_VALIDATION' || row.validation_status === 'WAITING_FOR_VALIDATION'"
              class="expand-value chips chips--orange"
        ><strong>In progress</strong></span>
        <span v-if="(row.validation_status.charAt(0).toUpperCase() + row.validation_status.slice(1).toLowerCase()) === 'Succeeded'"
              class="expand-value chips chips--success"
        ><strong>Sucess</strong></span>
        <span v-else
              class="expand-value chips chips--red"
        ><strong>Disabled</strong></span>
      </Col>
      <Col span="8">
        <span class="expand-key">Last update : </span>
        <span class="expand-value"><strong>{{ moment(row.modified).format('LLL') }}</strong></span>
      </Col>
      <br>

      <br>
      <Col span="8">
        <span class="expand-key">First ingest : </span>
        <span class="expand-value">{{ row.collects.minDate }}</span>
      </Col>
      <Col span="8">
        <span class="expand-key">Last ingest : </span>
        <span class="expand-value">{{ row.collects.maxDate }}</span>
      </Col>
    </Row>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data: function (){
    return {
      moment: moment
    }
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
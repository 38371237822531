<template>
  <div>
    <div class="list-items">
      <div
        class="list-items-actions"
        v-if="!userHasRole('vendors-viewer')"
      >
        <Button
          :to="{path: '/admin/properties/create'}"
          type="info"
          class="btn btn--elevated"
          icon="md-add"
        >
          {{ $t('global.property.new') }}
        </Button>
      </div>
    </div>
    <div>
      <Row>
        <Col span="24">
          <FilterTable
            name="properties"
            :container="true"
            :fetch-items="this.propertiesGetProperties"
            :allow-download="false"
            :default-paging="{direction: 'desc', sort: 'Properties.created'}"
            :fetch-items-params="{withCollects: 1}"
            namespace="property"
            :columns="columns"
            id-column="Name"
            :limit="20"
            :search-filters="['search']"
          />
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import expandRow from './expandRow'

import { mapActions, mapGetters } from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'

export default {
  name: 'AdminUserIndex',
  components: {
    FilterTable
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      loading: true,
      /**
       * @note createColumn -> @/mixins/tableColumn.js
       */
      columns: [
        // {
        //   type: 'expand',
        //   width: 50,
        //   render: (h, params) => {
        //     return h(expandRow, {
        //       props: {
        //         row: params.row
        //       }
        //     })
        //   }
        // },
        this.createColumn(this.$t('global.table.name'), null, 'Properties.displayName', row => ({
          tag: 'a',
          events: {
            click: () => window.open(row.website, '_blank').focus()
          },
          content: row => ({tag: 'strong', content: row.displayName})
        })),
        this.createColumn(this.$t('tags'), 'Properties.tags', null, row => ({
          tag: 'span',
          content: row.tags
        }), 120),
        this.createColumn(this.$t('global.table.status'), 'isEnabled', 'Properties.isEnabled', 'enable',80),
        this.createColumn(this.$t('global.table.validation_status'), null, 'Properties.validation_status', row => {
          let status = ''
          let color = ''
          switch (row.validation_status){
          case 'WAITING_FOR_VALIDATION':
            status = '⌛'
            color = 'warning'
            break
          case 'QUEUED':
            status = '⏳'
            color = 'warning'
            break
          case 'IN PROGRESS':
            status = '▶'
            color = 'primary'
            break
          case 'SUCCEEDED':
            status = '✅'
            color = 'success'
            break
          case 'FAILED':
            status = '❌'
            color = 'error'
            break
          default :
            status = ''
            color = ''
          }
          // const status = row.validation_status === 'WAITING_FOR_VALIDATION' || row.validation_status === 'QUEUED'
          //   ? '⌛'
          //   // : row.validation_status.charAt(0).toUpperCase() + row.validation_status.slice(1).toLowerCase()
          //   : '✅'
          // const color = row.validation_status.toLowerCase() === 'in progress' ? 'orange' : (row.validation_status.toLowerCase() === 'succeeded' ? 'success' : 'red')

          return {
            tag: 'span',
            classes: `chips chips--${color}`,
            content: status
          }
        }, 80),
        this.createColumn(this.$t('global.table.collect_status'), 'isCollectEnabled', 'Properties.isCollectEnabled', 'enable', 80),
        this.createColumn(this.$t('global.table.first_ingest'), 'collects.minDate', null, 'date', 120),
        this.createColumn(this.$t('global.table.last_ingest'), 'collects.maxDate', null, 'date', 120),
        this.createColumn(this.$t('global.table.collect_frequency'), null, 'Properties.collectFrequency', row => ({
          tag: 'span',
          content: row.collectFrequency
        }),80),
        this.createColumn(this.$t('global.table.collect_maxurls'), null, null, row => ({
          tag: 'span',
          content: row.collectOptions ? row.collectOptions.maxUrls : '15'
        }),80),

        this.createColumn(this.$t('global.table.collect_monthlycost'), null, null, row => ({
          tag: 'span',
          // using approx 47 instead of 30 or 31, since when dividing per scenarii we approximate the real max crawlable pages, when collectfrequency is 'once' we ca estimate 4 pages
          content: (row.isEnabled * row.isCollectEnabled) ? (Math.round(row.isEnabled * row.isCollectEnabled * (row.collectFrequency == "daily" ? 47 : 4) * ( row.collectOptions ? row.collectOptions.maxUrls : '15') * (this.userHasRole('super-admin')?( (row.collectOptions.browser == 'chrome_v93_amd64') ? 0.02 : 0.0165):0.99)) +" €") : ''
        }),80),
        this.createColumn(this.$t('global.table.manage'), null, null, row => ({
          tag: 'Button',
          classes: 'btn btn--elevated',
          props: {type: 'primary', to: `/admin/properties/view?propertyId=${row.id}`},
          content: this.$t('global.table.manage')
        }), 120)
      ]
    }
  },
  methods: {
    ...mapActions({
      propertiesGetProperties: 'property/getProperties'
    })
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole'
    })
  }
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Row', {
    staticClass: "expand-row"
  }, [_c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Activation status : ")]), _vm.row.isEnabled ? _c('span', {
    staticClass: "expand-value chips chips--success"
  }, [_c('strong', [_vm._v("Enabled")])]) : _c('span', {
    staticClass: "expand-value chips chips--red"
  }, [_c('strong', [_vm._v("Disabled")])])]), _c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Verification status : ")]), _vm.row.validation_status === 'WAITING_FOR_VALIDATION' || _vm.row.validation_status === 'WAITING_FOR_VALIDATION' ? _c('span', {
    staticClass: "expand-value chips chips--orange"
  }, [_c('strong', [_vm._v("In progress")])]) : _vm._e(), _vm.row.validation_status.charAt(0).toUpperCase() + _vm.row.validation_status.slice(1).toLowerCase() === 'Succeeded' ? _c('span', {
    staticClass: "expand-value chips chips--success"
  }, [_c('strong', [_vm._v("Sucess")])]) : _c('span', {
    staticClass: "expand-value chips chips--red"
  }, [_c('strong', [_vm._v("Disabled")])])]), _c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Last update : ")]), _c('span', {
    staticClass: "expand-value"
  }, [_c('strong', [_vm._v(_vm._s(_vm.moment(_vm.row.modified).format('LLL')))])])]), _c('br'), _c('br'), _c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("First ingest : ")]), _c('span', {
    staticClass: "expand-value"
  }, [_vm._v(_vm._s(_vm.row.collects.minDate))])]), _c('Col', {
    attrs: {
      "span": "8"
    }
  }, [_c('span', {
    staticClass: "expand-key"
  }, [_vm._v("Last ingest : ")]), _c('span', {
    staticClass: "expand-value"
  }, [_vm._v(_vm._s(_vm.row.collects.maxDate))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
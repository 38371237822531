var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "list-items"
  }, [!_vm.userHasRole('vendors-viewer') ? _c('div', {
    staticClass: "list-items-actions"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "to": {
        path: '/admin/properties/create'
      },
      "type": "info",
      "icon": "md-add"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.property.new')) + " ")])], 1) : _vm._e()]), _c('div', [_c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FilterTable', {
    attrs: {
      "name": "properties",
      "container": true,
      "fetch-items": this.propertiesGetProperties,
      "allow-download": false,
      "default-paging": {
        direction: 'desc',
        sort: 'Properties.created'
      },
      "fetch-items-params": {
        withCollects: 1
      },
      "namespace": "property",
      "columns": _vm.columns,
      "id-column": "Name",
      "limit": 20,
      "search-filters": ['search']
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }